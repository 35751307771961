.splitting .word, .splitting .char {
  display: inline-block;
}

.splitting .char {
  position: relative;
}

.splitting .char:before, .splitting .char:after {
  content: attr(data-char);
  visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.splitting {
  --word-center: calc((var(--word-total)  - 1) / 2);
  --char-center: calc((var(--char-total)  - 1) / 2);
  --line-center: calc((var(--line-total)  - 1) / 2);
}

.splitting .word {
  --word-percent: calc(var(--word-index) / var(--word-total));
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  --char-percent: calc(var(--char-index) / var(--char-total));
  --char-offset: calc(var(--char-index)  - var(--char-center));
  --distance: calc((var(--char-offset) * var(--char-offset)) / var(--char-center));
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  --distance-percent: calc((var(--distance) / var(--char-center)));
}

.splitting.cells img {
  width: 100%;
  display: block;
}

@supports (display: grid ) {
  .splitting.cells {
    visibility: hidden;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    grid-template: repeat(var(--row-total), 1fr) / repeat(var(--col-total), 1fr);
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    visibility: visible;
    width: calc(100% * var(--col-total));
    height: calc(100% * var(--row-total));
    left: calc(-100% * var(--col-index));
    top: calc(-100% * var(--row-index));
    position: absolute;
  }

  .splitting .cell {
    --center-x: calc((var(--col-total)  - 1) / 2);
    --center-y: calc((var(--row-total)  - 1) / 2);
    --offset-x: calc(var(--col-index)  - var(--center-x));
    --offset-y: calc(var(--row-index)  - var(--center-y));
    --distance-x: calc((var(--offset-x) * var(--offset-x)) / var(--center-x));
    --distance-y: calc((var(--offset-y) * var(--offset-y)) / var(--center-y));
  }
}
/*# sourceMappingURL=index.3cebd974.css.map */
